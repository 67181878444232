<template>
    <b-modal title="Form Sumber Kas Pengadaan" id="kas-modal" no-close-on-backdrop no-close-on-esc>
        <h1 class="text-danger text-center mb-2 mt-1">
            <strong>Rp {{ formatRupiah(totalPengadaan) }}</strong>
        </h1>
        <b-form-group label="Pilih Akun" class="mb-2">
            <v-select label="text" :options="akunOptions" v-model="akun" class="mb-1"></v-select>
        </b-form-group>
        <b-form-group label="Pilih Kas" class="mb-2">
            <v-select label="text" :options="kasOptions" v-model="form.id_kas" :reduce="option => option.value"></v-select>
            <i class="text-danger" v-if="invalidKas">Saldo Kas kurang</i>
        </b-form-group>

        <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit" :disabled="!isValidForm">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    components: {
        BModal, BFormGroup, BButton, vSelect
    },
    data: () => ({
        akun: null,
        form: {
            id: null,
            aktor: 'finance',
            id_akun: null,
            status: 1,
            id_kas: null
        }
    }),
    computed: {
        invalidAkun() {
            if(!this.akun) return false
            const akun = this.akunOptions.find(item => item.value == this.akun.value)
            if(!akun) return false

            return akun && (parseInt(akun.saldo) < 1 || parseInt(akun.saldo) < parseInt(this.totalPengadaan))
        },
        invalidKas() {
            const kas = this.kasOptions.find(item => item.value == this.form.id_kas)
            if(!kas) return false

            return kas && (parseInt(kas.saldo) < 1 || parseInt(kas.saldo) < parseInt(this.totalPengadaan))
        },
        isValidForm() {
            if(!this.akun || !this.form.id_kas) {
                return false
            }

            if(this.invalidKas) {
                return false
            }

            return true
        },
        totalPengadaan() {
            return this.$store.state.asset.currentTotalPengadaan
        },
        isKas() {
            if(this.akun && this.akun.kategori && this.akun.kategori.kategori == 'Kas & Bank') {
                return true
            }

            return false
        },
        kasOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.asset.kasOptions) )
        },
        akunOptions() {
            return JSON.parse( JSON.stringify(this.$store.state.asset.akunOptions) )
        }
    },
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form pemilihan sumber kas!'
                })
                return false
            }

            this.form.id_akun = this.akun.value
            this.form.id = this.$route.params.id
            this.$emit('submitKas', this.form)
        }
    }
}
</script>