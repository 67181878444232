<template>
    <div>
        <b-modal no-close-on-backdrop no-close-on-esc size="md" id="modal-new-asset" title="Tambah Aset Baru">
            <b-form-group label="Nama Aset" class="mb-2">
                <b-form-input v-model="form.nama" class="w-full"></b-form-input>
            </b-form-group>
            <b-row>
                <b-col sm="12" md="6" class="mb-2">
                    <b-form-group label="Jenis Aset">
                        <b-form-select
                        v-model="form.jenis"
                        :options="[{value: 1, text: 'TETAP'}, {value: 2, text: 'BHP'}]"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="6" class="mb-2">
                    <b-form-group label="Harga Dasar">
                        <b-form-input
                        v-model="form.harga_dasar"
                        @keyup="doFormatRupiah"
                        placeholder="Harga Dasar"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12" md="6" class="mb-2">
                    <b-form-group label="Kategori">
                        <v-select
                        v-model="form.id_kategori"
                        :options="kategoriOptions"
                        :reduce="(option) => option.value"
                        label="text"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="6" class="mb-2">
                    <b-form-group label="Satuan">
                        <v-select
                        v-model="form.id_satuan"
                        :options="satuanOptions"
                        :reduce="(option) => option.value"
                        label="text"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12" md="6" class="mb-2">
                    <b-form-group label="Volume Aset" >
                        <b-form-input v-model="form.volume" class="w-full"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="12" md="6" class="mb-2">
                    <b-form-group label="Tanggal Aset">
                        <b-form-input
                            placeholder="Ex: "
                            v-model="form.tanggal"
                            type="date"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <template #modal-footer>
                <b-button variant="primary" @click.prevent="submit" :disabled="!isValidForm">Simpan</b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import {BModal, BRow, BCol, BFormGroup, BFormInput, BButton, BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    data: () => ({
        form: {
            nama: null,
            jenis: null,
            volume: null,
            tanggal: null,
            harga_dasar: 0,
            id_kategori: null,
            id_satuan: null
        },
        satuanOptions: [],
        rawSatuan: [],
        kategoriOptions: []
    }),
    components: {
        BModal, BRow, BCol, BFormGroup, BFormInput, BButton, BFormSelect, vSelect
    },
    computed: {
        isValidForm() {
            if(
                !this.form.nama ||
                !this.form.jenis ||
                !this.form.volume ||
                !this.form.tanggal ||
                this.unFormatRupiah(this.form.harga_dasar) < 1 ||
                !this.form.id_kategori ||
                !this.form.id_satuan
            ) {
                return false
            }

            return true
        }
    },
    methods: {
        async submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form aset!'
                })
                return false
            }

            const payload = Object.assign({}, this.form)
            payload.harga_dasar = this.unFormatRupiah(payload.harga_dasar)

            try {
                const aset = await this.$store.dispatch("asset/save", [payload])
                const satuan = this.rawSatuan.find(item => item.id == aset.id_satuan)
                if(satuan) aset.satuan = satuan
                this.$emit('afterSubmit', aset)
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        },
        doFormatRupiah() {
            this.form.harga_dasar = this.formatRupiah(this.form.harga_dasar)
        },
        async getSatuan() {
            const satuan = await this.$store.dispatch('satuan/getData')
            this.rawSatuan = satuan
            this.satuanOptions = satuan.map(st => ({ value: st.id, text: st.satuan }))
        },
        async getKategori() {
            let kategori = await this.$store.dispatch("kategori-asset/getData");
            kategori = JSON.parse(JSON.stringify(kategori));
            let newKategori = [];
            kategori.map((item) => {
            newKategori.push({
                text: item.kategori,
                value: item.id,
            });
            });
    
            this.kategoriOptions = newKategori;
        },
    },
    created() {
        this.getKategori()
        this.getSatuan()
    }
}
</script>