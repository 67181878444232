<template>
    <b-overlay :show="loading">
        <div class="d-flex justify-content-end mb-2" v-if="canPay">
            <b-button variant="outline-primary" @click.prevent="print"><feather-icon icon="PrinterIcon"></feather-icon> Cetak Bukti Pengadaan</b-button>
        </div>
        <header class="mb-2">
            <b-card>
                <b-row>
                    <b-col cols="12">
                        <section class="d-flex justify-content-between">
                            <div>
                                <p><strong>No. Pengadaan</strong></p>
                                <p>{{ pengadaan.no }}</p>
                            </div>
                            <div>
                                <b-button v-if="canManage && pengadaan.status == 5 && pengadaan.selesai == 0" :disabled="!thereRincian" @click.prevent="$router.push(`/asset-penyimpanan/add?pg=${pengadaan.id}`)" variant="primary">
                                    <span class="align-middle">Tempatkan Aset</span>
                                    <feather-icon icon="ChevronRightIcon" class="ml-1">
                                    </feather-icon>
                                </b-button>
                                <b-button v-if="canApprove && [0, 2].includes(pengadaan.status)" size="sm" variant="info" @click.prevent="approve('approve')">
                                    Setujui Pengadaan
                                </b-button>
                                <b-button v-if="canApprove && pengadaan.status == 1" size="sm" class="ml-1" variant="outline-danger" @click.prevent="approve('cancel')">
                                    Batalkan Pengadaan
                                </b-button>
                                <b-button v-if="canPay && pengadaan.status == 1" variant="info" size="sm" @click.prevent="showModalKas(1)">
                                    Pilih Sumber Pengeluaran
                                </b-button>
                                <b-button v-if="canPay && pengadaan.status == 3" variant="outline-danger" size="sm" @click.prevent="cancelKas">
                                    Batalkan Pemilihan Kas
                                </b-button>
                                <b-button v-if="canManage && pengadaan.status == 3" variant="outline-success" class="ml-1" size="sm" @click.prevent="approve('approve')">
                                    Terima Pengadaan
                                </b-button>
                            </div>
                        </section>
                    </b-col>
                    <b-col md="3" sm="12">
                        <p><strong>Tanggal Pengadaan</strong></p>
                        <p :class="!isAdminAsset ? 'mb-2' : ''">{{ pengadaan.tanggal }}</p>
                        <p v-if="!isAdminAsset"><strong>Info Gudang Aset</strong></p>
                        <p v-if="!isAdminAsset">{{ gudang ? gudang.nama_gudang : '' }}</p>
                    </b-col>
                    <b-col md="2" sm="12">
                        <p><strong>Info Penempatan</strong></p>
                        <p v-if="pengadaan.selesai == 1"> <b-badge variant="success">Selesai</b-badge></p>
                        <p v-if="pengadaan.selesai == 0">Belum ditempatkan</p>
                    </b-col>
                    <b-col md="4" sm="12">
                        <p><strong>Keterangan</strong></p>
                        <p class="mb-1">{{pengadaan.keterangan ? pengadaan.keterangan : 'kosong'}}</p>
                        
                    </b-col>
                    <b-col md="2" sm="12">
                        <p><strong>Status</strong></p>
                        <b-badge variant="light-info">{{ pengadaan.status_ket ? capitalize(pengadaan.status_ket) : 'Menunggu persetujuan Owner' }}</b-badge>
                    </b-col>
                </b-row>
            </b-card>
        </header>
  
        <main>
            <b-card>
                <h3>Daftar Aset</h3>
                <b-button v-if="allowUpdate() && pengadaan.status == 0" variant="primary" class="mb-2" @click.prevent="addAsset">Pilih Aset</b-button>
                <b-table striped responsive="" hover :items="rincians" :fields="rincianFields" class="mb-2">
                    <template #cell(no)="row">
                        {{ (row.index + 1) }}
                    </template>
                    <template #cell(nama)="{item}">
                        <span v-if="item.asset">
                            {{ item.asset.nama }}
                        </span>
                        <i v-else class="text-danger">Aset tidak ditemukan</i>
                    </template>
                    <template #cell(harga_dasar)="{item}">
                        Rp {{ item.asset ? formatRupiah(item.asset.harga_dasar) : 0 }}
                    </template>
                    <template #cell(satuan)="{item}">
                        {{ item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-' }}
                    </template>
                    <template #cell(harga)="{item}">
                        Rp {{ formatRupiah( parseInt(item.harga)) }}
                    </template>
                    <template #cell(status)="{item}">
                        <!-- Barang diterima -->
                        <feather-icon v-if="item.status == 0 || item.status == 2" icon="XIcon" class="text-danger" size="24" />
                        <feather-icon v-else icon="CheckIcon" class="text-info" size="24" />

                    </template>
                    <template #cell(action)="{index}">
                        <feather-icon v-if="(allowDelete() && pengadaan.status == 0)" class="cursor-pointer text-danger" @click="remove(index)" icon="MinusCircleIcon" size="24"></feather-icon>
                    </template>
                </b-table>
                <div class="d-flex justify-content-end">
                    <h4 class="mb-4">Total: Rp {{ formatRupiah(totalRincian) }}</h4>
                </div>
                <div class="d-flex justify-content-end">
                    <b-button v-if="allowUpdate() && pengadaan.status == 0" variant="primary" @click.prevent="submit" :disabled="(totalRincian < 1)">Simpan</b-button>
                </div>
            </b-card>
        </main>

        <!-- Modal Asset -->
        <asset-modal :assets="assets" @submit="onChooseAsset"></asset-modal>
        <!-- / -->
        <kas-modal @submitKas="submitKas" v-if="canPay"></kas-modal>
    </b-overlay>
  </template>
  <script>
    import {BRow, BCol, BCard, VBTooltip,    BBadge, BButton, BTable, BModal, BOverlay} from 'bootstrap-vue'
    import AssetModal from './components/Modal.vue'
    import KasModal from './components/ModalKas.vue'
    import service from '@/services'

    export default {
        components: {
            VBTooltip,
            KasModal,
            AssetModal,
            BOverlay,
            BRow,
            BModal,
            BCol,
            BButton,
            BCard,
            BBadge,
            BTable
        },
        directives: {
            "b-tooltip": VBTooltip
        },
        computed: {
            thereRincian() {
                return this.rincians.length > 0 && this.rincians.some(item => item.status && item.status == 1)
            },
            totalRincian(){
                if(this.rincians.length < 1) {
                    return 0
                }
                const rincians = this.rincians.filter(item => item.asset != null)
                const total = rincians.reduce((total, item) => {
                    return total += (item.jumlah*item.asset.harga_dasar)
                }, 0)
                return total
            }
        },
        data: () => ({
            gudang: null,
            loading: false,
            assets: [],
            pengadaan: {
                no: null,
                keterangan: null,
                selesai: 0,
                tanggal: null
            },
            rincianFields: [
                {key: 'no', label: 'No', sortable: true},
                {key: 'nama', label: 'Nama Aset', sortable: true},
                {key: 'harga_dasar', label: 'Harga Dasar', sortable: true},
                {key: 'jumlah', label: 'Jumlah', sortable: true},
                {key: 'satuan', label: 'Satuan', sortable: true},
                {key: 'harga', label: 'Harga Total', sortable: true},
                {key: 'status', label: 'Barang Diterima'},
                {key: 'action', label: '#'},
            ],
            rincians: [],
            actionTypeFinance: 1,
            canApprove: false, // untuk owner
            canManage: false, // ntuk admin aset menempatkan barang
            canPay: false // nutuk finance pilih akun
        }),
        methods: {
            async print() {
                try {
                    this.loading = true
                    const config = {
                        method: 'get',
                        url: `/cetak-pengadaan-asset/${this.$route.params.id}`,
                        headers: {
                            Authorization: `bearer ${localStorage.token}`
                        },
                        responseType: 'blob'
                    }
                    
                    const response = await service.sendRequest(config)
                    const result = response.data
                    this.loading = false
                    window.open(URL.createObjectURL(result))
                }
                catch(e) {
                    this.loading = false
                    let error = {
                        message: ''
                    }
                    if (e.response && e.response.data) {
                        const blob = e.response.data
                        const text = await blob.text()
                        const errorJson = JSON.parse(text)

                        error = errorJson
                    }
                    else {
                        error = e
                    }

                    this.displayError(error)
                    return false
                }
            },
            setField() {
                if(!this.isAdminAsset) {
                    this.rincianFields = [
                        {key: 'no', label: 'No', sortable: true},
                        {key: 'nama', label: 'Nama Aset', sortable: true},
                        {key: 'harga_dasar', label: 'Harga Dasar', sortable: true},
                        {key: 'jumlah', label: 'Jumlah', sortable: true},
                        {key: 'satuan', label: 'Satuan', sortable: true},
                        {key: 'harga', label: 'Harga Total', sortable: true},
                    ]
                }
            },
            async cancelKas() {
                this.$swal({
                    title: 'Anda yakin?',
                    text: 'Setelah dibatalkan, Kas akan ditarik kembali',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Batal',
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger ml-1',
                    },
                    buttonsStyling: false
                })
                .then(async res => {
                    if(res.value) {
                        try {
                            const payload = {
                                id: this.$route.params.id,
                                aktor: 'finance'
                            }

                            this.loading = true
                            await this.$store.dispatch('asset/cancelPengadaan', [payload])
                            this.loading = false
                            this.displaySuccess({
                                message: 'Kas pengadaan berhasil dicancel'
                            })
                            setTimeout(() => window.location.reload(), 1000)
                        }
                        catch(e) {
                            this.loading = false
                            this.displayError(e)
                            return false
                        }
                    }
                })
            },
            // approvePengadaanRincian
            async submitRincian(payloads) {
                try {
                    this.loading = true
                    this.$bvModal.hide('approve-rincian-modal')
                    await this.$store.dispatch('asset/approvePengadaanRincian', payloads)
                    this.loading = false
                    this.displaySuccess({
                        message: 'Barang Pengadaan berhasil disetujui'
                    })
                    // setTimeout(() => window.location.reload(), 1000)
                }
                catch(e) {
                    this.loading = false
                    this.$bvModal.show('approve-rincian-modal')
                    this.displayError(e)
                    this.displayError(e)
                    return false
                }
            },
            showApproveRincianModal() {
                this.$bvModal.show('approve-rincian-modal')
            },
            async submitKas(payload) {
                try {
                    this.loading = true
                    this.$bvModal.hide('kas-modal')

                    await this.$store.dispatch('asset/approvePengadaan', [payload])
                    this.loading = false
                    this.displaySuccess({
                        message: payload.status == 1 ? 'Pengadaan telah disetujui' : 'Pengadaan berhasil dibatalkan'
                    })
                    setTimeout(() => window.location.reload(), 1000)
                }
                catch(e) {
                    this.loading = false
                    this.$bvModal.show('kas-modal')
                    this.displayError(e)
                    return false
                }
            },
            async getAkun() {
                const params = {
                    jenis: [2,3]
                }
                const akuns = await this.$store.dispatch('akun/getData', params)
                const akunOptions = akuns.map(akun => {
                    const akunName = `${akun.nama} -> Rp ${this.formatRupiah(akun.saldo)}`
                    return {text: akunName, value: akun.id, saldo: akun.saldo, kategori: akun.kategori}
                })
                this.$store.commit('asset/SET_DATA_AKUN', akunOptions)
            },
            async getKas() {
                const listKas = await this.$store.dispatch('kas/getData')
                const kasOptions = listKas.map(kas => {
                    const kasName = `${kas.nama} -> Rp ${this.formatRupiah(kas.saldo)}`
                    return {text: kasName, saldo: kas.saldo, value: kas.id}
                })
                this.$store.commit('asset/SET_DATA_KAS', kasOptions)
            },
            showModalKas(action = 1) {
                this.actionTypeFinance = action
                this.$store.commit('asset/SET_CURRENT_TOTAL_PENGADAAN', parseInt(this.pengadaan.informasi.total_pembelian))
                this.$bvModal.show('kas-modal')
            },
            async approve(actionType) {
                let text = actionType == 'approve' ? 'Anda akan menandai selesai pengadaan ini' : ''
                if(this.isOwner || this.isGM) {
                    text = actionType == 'approve' ? 'Admin Aset bisa menempatkan barang & Finance bisa memilih sumber kas' : 'Anda akan membatalkan pengadaan ini'
                }
                else if(this.isFinance) {
                    text = actionType == 'approve' ? 'Admin Aset bisa menandai selesai pengadaan ini' : 'Anda akan membatalkan pemilihan kas pengadaan ini'
                }

                this.$swal({
                    title: 'Anda yakin?',
                    text,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Batal',
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger ml-1',
                    },
                    buttonsStyling: false
                })
                .then(res => {
                    if(res.value) {
                        this.doApprove(actionType)
                    }
                })
            },
            async doApprove(actionType) { // by onwer
                
                try {
                    const payload = {
                        id: this.$route.params.id,
                        aktor: this.isOwner || this.isGM ? 'gm' : (this.isFinance ? 'finance' : 'gudang'),
                        status: actionType == 'approve' ? 1 : 2
                    }
                    this.loading = true
                    await this.$store.dispatch('asset/approvePengadaan', [payload])
                    // Update barang diterima
                    this.rincians.map(rincian => {
                        rincian.status = 1
                    })

                    await this.submit()
                    this.loading = false
                    this.displaySuccess({
                        message: payload.status == 1 ? 'Pengadaan telah disetujui' : 'Pengadaan berhasil dibatalkan'
                    })
                    setTimeout(() => window.location.reload(), 1000)
                }
                catch(e) {
                    this.loading = false
                    this.displayError(e)
                    return false
                }
            },
            async submit() {
                if(this.totalRincian < 1) {
                    this.displayError({
                        message: 'Harap pilih aset barang!'
                    })
                    return false
                }

                const payloads = []
                this.rincians.map(rincian => {
                    const payload = {
                        pengadaan_id: parseInt(this.$route.params.id),
                        id_satuan: rincian.id_satuan,
                        asset_id: rincian.asset_id,
                        harga: rincian.asset ? rincian.asset.harga_dasar : 0,
                        jumlah: rincian.jumlah,
                        status: rincian.status
                    }
                    if(rincian.id) {
                        payload.id = rincian.id
                    }
                    payloads.push(payload)
                })

                try {
                    this.loading = true
                    await this.$store.dispatch('asset/savePengadaanRincian', payloads)
                    this.loading = false
                    this.displaySuccess({
                        message: 'Pengadaan berhasil diperbaharui'
                    })
                    this.getRincian()
                }
                catch(e) {
                    this.loading = false
                    this.displayError(e)
                    return false
                }
            },
            async remove(index) {
                const item = this.rincians[index]
                if(item.id) { // rm via api
                    const payload = {
                        id: item.id,
                        asset_id: item.asset_id,
                        fungsi: 2
                    }
                    
                    try {
                        await this.$store.dispatch('asset/savePengadaanRincian', [payload])
                        this.rincians.splice(index, 1)
                    }
                    catch(e) {
                        this.displayError(e)
                        return false
                    }
                }
                else {
                    this.rincians.splice(index, 1)
                }
            },
            onChooseAsset(rincians) {
                if(this.rincians.length < 1) {
                    this.rincians = rincians
                }
                else {
                    rincians.map(rinci => this.rincians.push(rinci))
                }
                this.$bvModal.hide('modal-asset')
            },
            addAsset() {
                this.$bvModal.show('modal-asset')
            },
            async getPengadaan() {
                let pengadaan = this.$store.getters['asset/searchPengadaan'](this.$route.params.id)
                if(!pengadaan) {
                    pengadaan = await this.$store.dispatch('asset/getPengadaanById', this.$route.params.id)
                }

                this.pengadaan = pengadaan
            },
            async getRincian() {
                const params = {order: 'desc', pengadaan_id: this.$route.params.id}
                this.rincians = await this.$store.dispatch('asset/getRincianPengadaan', params)
            },
            async getAssets() {
                const assets = await this.$store.dispatch('asset/getBarang')
                this.assets = assets.data
            },
            async getPermissionApprove() {
                const currentMenu = await this.currentMenu(this.$route.meta.parent)

                const status = await this.$store.dispatch('statusrole/getData', {
                    menu_id: currentMenu.id,
                    level_id: this.user.level.id
                })

                if(status && status.length) {
                    this.canManage = status.some(st => st.data_status == 1)
                    this.canApprove = status.some(st => st.data_status == 2)
                    this.canPay = status.some(st => st.data_status == 3)
                }
            },
            async getGudangInfo() {
                if(this.pengadaan && this.pengadaan.gudang_id) {
                    const gudang = await this.$store.dispatch('gudang/getDataById', this.pengadaan.gudang_id)
                    this.gudang = gudang
                }
            }
        },
        async created() {
            this.setField()
            this.loading = true
            this.getPengadaan()
            this.getRincian()
            await this.getAssets()
            await this.getGudangInfo()
            await this.getPermissionApprove()
            if(this.canPay) {
                await this.getAkun()
                await this.getKas()
            }
            this.loading = false
        }
    }
  </script>